var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "Posttype:" }, [
      _c("div", { staticClass: "Posttype:container" }, [
        _c("div", { staticClass: "Posttype:container40" }, [
          _c("div", { staticClass: "Posttype:box poststable:" }, [
            _c(
              "div",
              { staticClass: "Posttype:box-wrap" },
              [
                _c("PostsDatatable", {
                  key: _vm.postDTkey,
                  on: { setPost: _vm.setPostNoSession }
                })
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "Posttype:container60" },
          [
            _c("div", { staticClass: "Posttype:box latestpost:" }, [
              _c(
                "div",
                { staticClass: "Posttype:box-wrap" },
                [
                  _c("div", { staticClass: "Posttype:box-title" }, [
                    _vm._v("Latest matches")
                  ]),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.posts, function(post, idx) {
                          return _c(
                            "v-col",
                            { key: idx, attrs: { cols: "6" } },
                            [
                              _c("Post", {
                                attrs: { post: post },
                                on: {
                                  deletePost: _vm.deletePost,
                                  setPost: _vm.setPostNoSession
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "Posttype:box uploadpost:" }, [
              _c("div", { staticClass: "Posttype:box-wrap" }, [
                _c("div", { staticClass: "Posttype:box-title" }, [
                  _vm._v("Custom Upload File")
                ]),
                _c(
                  "div",
                  { staticClass: "Posttype:container" },
                  [_c("Uploader")],
                  1
                )
              ])
            ]),
            _c("Menu")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }