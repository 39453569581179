var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { height: "100%" } },
    [
      _c("v-card-title", [
        _vm._v(
          " " +
            _vm._s(_vm.post.hometeam) +
            " - " +
            _vm._s(_vm.post.awayteam) +
            " "
        )
      ]),
      _c("v-card-text", [
        _vm._v(" " + _vm._s(_vm.post.matchdate)),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.post.description) + " ")
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { color: "green" },
              on: {
                click: function($event) {
                  return _vm.$emit(
                    "setPost",
                    _vm.post.postId,
                    _vm.post.hometeam,
                    _vm.post.awayteam
                  )
                }
              }
            },
            [_vm._v("Play")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }