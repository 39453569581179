<template>

  <v-card height="100%">
    <v-card-title>
      {{ post.hometeam }} - {{ post.awayteam }}
    </v-card-title>
    <v-card-text>
      {{ post.matchdate }}<br>
      {{ post.description }}
    </v-card-text>
    <v-card-actions>
      <!--v-btn color="red" @click="$emit('deletePost',post.postId)">Delete</v-btn-->
      <v-btn color="green" @click="$emit('setPost',post.postId, post.hometeam, post.awayteam)">Play</v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
export default {
  props: ['post'], 
  data: () => ({

  }),
  created() {
  },
  methods: {
  }
};
</script>
