<template>
  <v-app>
    <div class="Posttype:">
      <div class="Posttype:container">
        <div class="Posttype:container40">
          <div class="Posttype:box poststable:">
            <div class="Posttype:box-wrap">
              <PostsDatatable @setPost="setPostNoSession" :key="postDTkey" />
              <!-- <Datepicker /> -->
            </div>
          </div>
        </div>
        <div class="Posttype:container60">
          <div class="Posttype:box latestpost:">
            <div class="Posttype:box-wrap">
              <div class="Posttype:box-title">Latest matches</div>
              <v-container>
                <v-row>
                  <v-col cols="6" v-for="(post, idx) in posts" :key="idx">
                    <Post
                      :post="post"
                      @deletePost="deletePost"
                      @setPost="setPostNoSession"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <!--div class="Posttype:container">
            <div
              class="Posttype:single"
              v-for="(t, index) in listid"
              :key="index"
            >
              <div class="Posttype:single_val" @click="setPost(t.postId)">
                {{ t.name }}
              </div>
            </div> 
           </div>-->
            </div>
          </div>
          <div class="Posttype:box uploadpost:">
            <div class="Posttype:box-wrap">
              <div class="Posttype:box-title">Custom Upload File</div>
              <div class="Posttype:container">
                <Uploader />
              </div>
            </div>
          </div>
          <Menu />
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import AdmZip from "adm-zip";
import Uploader from "@/components/posttype/upload";
import idb from "@/mixins/idbPosts.js";

// import Datepicker from "@/components/posttype/datepicker";
import Menu from "@/components/general/nav/menu.vue";
import Post from "@/components/general/card/Post.vue";
import PostsDatatable from "@/components/datatable/PostsDatatable.vue";
export default {
  data() {
    return {
      listid: "",
      startDate: "",
      bookings: [],
      postDTkey: 0,
      deletePostdialog: false,
    };
  },
  components: {
    Uploader,
    Menu,
    Post,
    PostsDatatable,
    // Datepicker,
  },
  watch: {
    bookings(val) {
      console.log(val);
    },
    startDate(val) {
      console.log(val);
    },
    upload(val) {
      if (val == true) {
        this.getIds();
        this.forcePostDTRerender();
      }
    },
    listpost(val) {
      this.listid = val;
    },
  },
  computed: {
    postid() {
      return this.$store.state.post.postid;
    },
    sessionid() {
      return this.$store.state.post.sessionid;
    },
    upload() {
      return this.$store.state.post.uploaded;
    },
    listpost() {
      return this.$store.state.post.listpost;
    },
    userid() {
      return this.$store.state.user.user.id;
    },
    posts() {
      return this.$store.state.post.listpost;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    getIds() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/posts4user/" + this.userid,
          this.headerConfig
        )
        .then((response) => {
          this.listid = response.data.slice(0, 4);
          this.$store.commit("changelistpost", response.data.slice(0, 4));
        });
    },
    reverseArr(input) {
      var ret = new Array();
      for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    },
    deletePost(postid) {
      console.log("deletePost");
    },
    setPost(postid, hometeamname, awayteamname) {
      /**
       *  check if sessionid is set if not go ahead for the set up sessionid
       *  once it replies make a POST /session/create with sessionid
       *
       *  if not make just save the idpost in the store
       *
       *  Then make the post the create the animation POST /session/post
       *  post url-> http://ksportlive.racebi.com
       *  docs: http://ksportlive.racebi.com:3901/docs/api/post/session/post.md
       */
      if (!this.sessionid) {
        axios
          .get(
            process.env.VUE_APP_API_URL + "/session/getid/" + this.userid,
            this.headerConfig
          )
          .then((response) => {
            console.log(response);
            const sessionid = response.data.sessionid;
            this.sessionCreate(sessionid, postid, hometeamname, awayteamname);
            // make the post
          });
      } else {
        this.sessionPost(this.sessionid, postid, hometeamname, awayteamname);
      }
    },
    async setPostNoSession(postid, hometeamname, awayteamname) {
      const data = {
        postid: postid,
      };
      await axios
        .post(process.env.VUE_APP_POSTS_SERVER + "/postmaxtime", data)
        .then((response) => {
          this.$store.commit("setpostid", postid);
          this.$store.commit("setgametime", response.data.maxtime);
        });
      await axios
        .get(process.env.VUE_APP_POSTS_SERVER + "/post/" + postid)
        .then((response) => {
          let data = response.data[0];
          if (data.videoUrl != null) {
            this.$store.commit("videoUrl", data.videoUrl);
            this.$store.commit("videoId", data.videoId);
            this.$store.commit("videoSkin", data.videoSkin);
            let pInterval = [];
            pInterval[0] = {
              start: data.period1_start,
              end: data.period1_end,
            };
            pInterval[1] = {
              start: data.period2_start,
              end: data.period2_end,
            };
            pInterval[2] = {
              start: data.period3_start,
              end: data.period3_end,
            };
            pInterval[3] = {
              start: data.period4_start,
              end: data.period4_end,
            };
            this.$store.commit("periodsIntervals", pInterval);
            this.$store.commit("hasVideo", true);
          } else {
            this.$store.commit("videoUrl", null);
            this.$store.commit("videoId", null);
            this.$store.commit("videoSkin", null);
            let pInterval = [];
            this.$store.commit("periodsIntervals", pInterval);
            this.$store.commit("hasVideo", false);
          }
        })
        .catch((err) => {
          console.log("Error during post info retreival " + err);
        });
      // Loads data into indexedDB
      await idb.clearData();
      const file_url =
        process.env.VUE_APP_POSTS_SERVER + "/postdocs/" + postid + ".zip";
      const rows = await this.getRowsFromZip(file_url);
      await idb.saveRows(rows);
      this.$store.commit("players", []);
      this.$store.commit("setpostactive", true);
      this.$store.commit("setliveactive", false);
      this.$store.commit("setpoststart", 0);
      this.$store.commit("setpostend", 0);
      // this.$store.commit("incrAnimationCounter");
      // this.$store.commit("setpaused", false);
      // this.$store.commit("setinittime", 10);
      this.$store.commit("sethometeamname", hometeamname);
      this.$store.commit("setawayteamname", awayteamname);
      // Interrompo la lettura del Websocket XY
      this.$store.commit("changeidgame", null);
      this.$router.push("/field");
    },
    sessionCreate(sessionid, postid, hometeamname, awayteamname) {
      axios
        .post(process.env.VUE_APP_POSTS_SERVER + "/session/create", {
          sessionid: sessionid,
        })
        .then((response) => {
          console.log(response);
          this.sessionPost(sessionid, postid, hometeamname, awayteamname);
        });
    },
    async sessionPost(sessionid, postid, hometeamname, awayteamname) {
      const data = {
        sessionid: sessionid,
        postid: postid,
      };
      await axios
        .post(process.env.VUE_APP_POSTS_SERVER + "/session/post", data)
        .then((response) => {
          console.log(response);
          this.$store.commit("setsessionid", sessionid);
          this.$store.commit("setpostid", postid);
          this.$store.commit("setgametime", response.data.maxtime);
        });
      // Loads data into indexedDB
      await idb.clearData();
      const file_url =
        process.env.VUE_APP_POSTS_SERVER + "/postdocs/" + postid + ".zip";
      const rows = await this.getRowsFromZip(file_url);
      await idb.saveRows(rows);
      this.$store.commit("players", []);
      this.$store.commit("setpostactive", true);
      this.$store.commit("setliveactive", false);
      this.$store.commit("setpoststart", 0);
      this.$store.commit("setpostend", 0);
      // this.$store.commit("incrAnimationCounter");
      // this.$store.commit("setpaused", false);
      // this.$store.commit("setinittime", 10);
      this.$store.commit("sethometeamname", hometeamname);
      this.$store.commit("setawayteamname", awayteamname);
      // Interrompo la lettura del Websocket XY
      this.$store.commit("changeidgame", null);
      this.$router.push("/field");
    },
    async getRowsFromZip(file_url) {
      return new Promise((resolve, reject) => {
        axios({
          url: file_url,
          method: "GET",
          responseType: "arraybuffer",
          responseEncoding: null,
        }).then((response) => {
          // convert arraybuffer to buffer
          var buffer = new Buffer(response.data.byteLength);
          var view = new Uint8Array(response.data);
          for (var i = 0; i < buffer.length; ++i) {
            buffer[i] = view[i];
          }

          var zip = new AdmZip(buffer);
          var zipEntries = zip.getEntries();
          resolve(
            zipEntries[0]
              .getData()
              .toString("utf8")
              .split("\n")
          );
        });
      });
    },
    forcePostDTRerender() {
      this.postDTkey += 1;
    },
  },
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    } else {
      this.getIds();
    }
  },
  mounted() {
    this.$store.commit("setinittime", -1);
  },
};
</script>

<style lang="scss">
.Posttype\: {
  padding: 40px;
  &container {
    display: flex;
  }
  &box {
    color: white;
    padding: 15px;
    // background-image: url("~@/assets/images/general/sfondo.jpg");
    position: relative;
    &-wrap {
      border: var(--border);
      padding: 25px;
    }
    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
  &container {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
  }
  &container40 {
    width: 50%;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    overflow-x: scroll;
  }
  &container60 {
    width: 50%;
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
    flex-direction: column;
  }
  &single {
    width: (100%/3);
    padding: 20px;
    &_val {
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 30px;
    }
  }

  .latestpost\: {
    width: 100%;
    .Posttype\:box-wrap {
      height: 460px;
    }
  }
  .uploadpost\: {
    width: 100%;
    .Posttype\:box-wrap {
      height: 230px;
    }
  }
  .poststable\: {
    width: 100%;
    .Posttype\:box-wrap {
      height: 720px;
    }
  }
}
</style>
