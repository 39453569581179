<template>
  <div class="">
    <div class="Posttype:box-title">Active Posts</div>
    <v-card>
      <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want delete selected post?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="runDeleteConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :search="search"
        :page="page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="posts"
        :options.sync="options"
        :server-items-length="totalPassengers"
        :items-per-page="15"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
             <td class="text-center truncate">{{ item.description }}</td>
            <td class="text-center truncate">{{ item.hometeam }}</td>
            <td class="text-center truncate">{{ item.awayteam }}</td>
            <td class="text-center truncate">{{ item.matchdate }}</td>
            <td class="text-center">
              <v-icon class="mr-2" @click="handleClick(item)">
                mdi-open-in-app
              </v-icon>
              <v-icon class="mr-2" @click="runDelete(item)">
                mdi-delete-outline
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
export default {
  name: "PostsDatatable",
  data() {
    return {
      search: "",
      page: 1,
      dialogDelete: false,
      totalPassengers: 0,
      numberOfPages: 0,
      posts: [],
      loading: true,
      options: {},
      headers: [
        { text: "Name", value: "description", align: "center" },
        { text: "Home Team", value: "hometeam", align: "center" },
        { text: "Away Team", value: "awayteam", align: "center" },
        { text: "Date", value: "matchdate", align: "center", sortable: false },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    overlay: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.readDataFromAPI();
      },
    },
    deep: true,
    overlay (val) {
      val && setTimeout(() => {
        this.overlay = false
      }, 3000)
    },
  },
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  methods: {
    readDataFromAPI() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      //console.log("Page Number ", page, itemsPerPage);

      axios
        .get(process.env.VUE_APP_API_URL + "/posts4user/" + this.userid, this.headerConfig)
        .then((response) => {
          this.loading = false;
          this.posts = response.data;
          //console.log(this.posts);
          this.totalPassengers = response.data.totalPassengers;
          this.numberOfPages = response.data.totalPages;
        });
    },
    handleClick(row) {
      console.log("Clicked On Post:" + row.postId);
      this.overlay = true;
      this.$emit("setPost", row.postId, row.hometeam, row.awayteam);
      this.overlay = true;
    },
    runDelete(item) {
      this.editedIndex = this.posts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    runDeleteConfirm() {
      // Recupera la lista di tutti i container per i match attivi
       const param = {
        postId: this.editedItem.postId
      };
     axios
        .post(process.env.VUE_APP_API_URL + "/post/delete", param)
        .then((response) => {
          alert("Post Deleted");
        })
        .catch((err) => {
          alert(err);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      window.location.reload();
    },
    reloadPage() {
      window.location.reload();
    },
  },
  mounted() {
    this.readDataFromAPI();
  },
};
</script>
<style>
    .truncate {
       max-width: 1 0vw;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
    }
</style>