var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "uploadpost:wrap" }, [
    _c("div", { staticClass: "uploadpost:container" }, [
      _c("div", { staticClass: "uploadpost:upload" }, [
        _c("div", { staticClass: "upload-btn-wrapper" }, [
          _c("button", { staticClass: "btn" }, [
            _c(
              "svg",
              {
                staticStyle: { width: "110px", height: "110px" },
                attrs: { viewBox: "0 0 24 24" }
              },
              [
                _c("path", {
                  attrs: {
                    fill: "#fdf800",
                    d:
                      "M19.35,10.04C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.04M19,18H6A4,4 0 0,1 2,14C2,11.95 3.53,10.24 5.56,10.03L6.63,9.92L7.13,8.97C8.08,7.14 9.94,6 12,6C14.62,6 16.88,7.86 17.39,10.43L17.69,11.93L19.22,12.04C20.78,12.14 22,13.45 22,15A3,3 0 0,1 19,18M8,13H10.55V16H13.45V13H16L12,9L8,13Z"
                  }
                })
              ]
            )
          ]),
          _c("input", {
            attrs: { id: "file", type: "file", name: "avatar" },
            on: { change: _vm.onFileSelected }
          }),
          _c("input", {
            attrs: { type: "hidden", id: "userid" },
            domProps: { value: _vm.userid }
          })
        ])
      ]),
      _c("div", { staticClass: "uploadpost:data" }, [
        _c("div", { staticClass: "uploadpost:data-desc" }, [
          _c("label", { attrs: { for: "matchdescr" } }, [_vm._v("Name")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.matchdescr,
                expression: "matchdescr"
              }
            ],
            ref: "filename",
            attrs: { type: "text", id: "matchdescr", name: "matchdescr" },
            domProps: { value: _vm.matchdescr },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.matchdescr = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "uploadpost:data-date" }, [
          _c("label", { attrs: { for: "matchdate" } }, [_vm._v("Date")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.matchdate,
                expression: "matchdate"
              }
            ],
            staticClass: "uploadpost:date",
            attrs: {
              type: "date",
              id: "matchdate",
              name: "matchdate",
              min: "2021-01-01",
              max: "2041-12-31"
            },
            domProps: { value: _vm.matchdate },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.matchdate = $event.target.value
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "uploadpost:data" }, [
        _c("div", { staticClass: "uploadpost:data-desc" }, [
          _c("label", { attrs: { for: "hometeam" } }, [_vm._v("Home Team")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hometeam,
                expression: "hometeam"
              }
            ],
            ref: "",
            attrs: { type: "text", id: "hometeam", name: "hometeam" },
            domProps: { value: _vm.hometeam },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.hometeam = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "uploadpost:data-desc" }, [
          _c("label", { attrs: { for: "awayteam" } }, [_vm._v("Away Team")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.awayteam,
                expression: "awayteam"
              }
            ],
            ref: "",
            attrs: { type: "text", id: "awayteam", name: "awayteam" },
            domProps: { value: _vm.awayteam },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.awayteam = $event.target.value
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "uploadpost:data" }, [
        _c("div", { staticClass: "uploadpost:data-desc" }, [
          _c("label", { attrs: { for: "analyisteam" } }, [
            _vm._v("Analysis Team")
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.analysisteam,
                  expression: "analysisteam"
                }
              ],
              attrs: { name: "analysisteam", id: "analysisteam" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.analysisteam = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.teams, function(team, index) {
              return _c(
                "option",
                { key: index, domProps: { value: team.id } },
                [_vm._v(_vm._s(team.long_desc) + " ")]
              )
            }),
            0
          )
        ]),
        _c("input", {
          staticClass: "uploadpost:button-el",
          attrs: {
            id: "submitBtn",
            type: "button",
            value: "Upload",
            disabled: !_vm.isUploadEnabled
          }
        })
      ])
    ]),
    _c("div", { staticClass: "uploadpost:progress" }, [
      _c("div", { ref: "progressbar", staticClass: "uploadpost:progress-bar" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }