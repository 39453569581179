<template>
  <div class="uploadpost:wrap">
    <div class="uploadpost:container">
      <div class="uploadpost:upload">
        <div class="upload-btn-wrapper">
          <button class="btn">
            <svg style="width: 110px; height: 110px" viewBox="0 0 24 24">
              <path
                fill="#fdf800"
                d="M19.35,10.04C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.04M19,18H6A4,4 0 0,1 2,14C2,11.95 3.53,10.24 5.56,10.03L6.63,9.92L7.13,8.97C8.08,7.14 9.94,6 12,6C14.62,6 16.88,7.86 17.39,10.43L17.69,11.93L19.22,12.04C20.78,12.14 22,13.45 22,15A3,3 0 0,1 19,18M8,13H10.55V16H13.45V13H16L12,9L8,13Z"
              />
            </svg>
          </button>
          <input id="file" type="file" name="avatar" @change="onFileSelected" />
          <input type="hidden" id="userid" :value="userid" />
        </div>
      </div>

      <div class="uploadpost:data">
        <div class="uploadpost:data-desc">
          <label for="matchdescr">Name</label>
          <input
            type="text"
            id="matchdescr"
            name="matchdescr"
            ref="filename"
            v-model="matchdescr"
          />
        </div>
        <div class="uploadpost:data-date">
          <label for="matchdate">Date</label>
          <input
            type="date"
            id="matchdate"
            class="uploadpost:date"
            name="matchdate"
            min="2021-01-01"
            max="2041-12-31"
            v-model="matchdate"
          />
        </div>
      </div>
      <div class="uploadpost:data">
        <div class="uploadpost:data-desc">
          <label for="hometeam">Home Team</label>
          <input
            type="text"
            id="hometeam"
            name="hometeam"
            ref=""
            v-model="hometeam"
          />
        </div>
        <div class="uploadpost:data-desc">
          <label for="awayteam">Away Team</label>
          <input
            type="text"
            id="awayteam"
            name="awayteam"
            ref=""
            v-model="awayteam"
          />
        </div>
      </div>
      <div class="uploadpost:data">
        <div class="uploadpost:data-desc">
          <label for="analyisteam">Analysis Team</label>
          <select name="analysisteam" id="analysisteam" v-model="analysisteam">
            <option v-for="(team, index) in teams" :key="index" :value="team.id"
              >{{ team.long_desc }}
            </option>
          </select>
        </div>
        <input
          id="submitBtn"
          class="uploadpost:button-el"
          type="button"
          value="Upload"
          :disabled="!isUploadEnabled"
        />
      </div>
    </div>
    <div class="uploadpost:progress">
      <div class="uploadpost:progress-bar" ref="progressbar"></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import SparkMD5 from "spark-md5";
window.jQuery = $;
export default {
  data() {
    return {
      uploadname: "",
      teams: [],
      matchdescr: "",
      matchdate: "",
      hometeam: "",
      awayteam: "",
      analysisteam: "",
    };
  },
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    isUploadEnabled: function() {
      return (
        this.matchdescr != "" &&
        this.matchdate != "" &&
        this.hometeam != "" &&
        this.awayteam != "" &&
        this.analysisteam != ""
      );
    },
  },
  methods: {
    onFileSelected(e) {
      // console.log(e)
      // console.log(e.target.files[0])
      this.file = e.target.files[0];
      this.filename = e.target.files[0].name;
      this.matchdescr = this.filename.split(".")[0];
    },
    percentage(partial, total) {
      let loading = (partial / total) * 100;
      return loading;
    },
    getDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;

      return today;
    },
    getTeams() {
      axios
        .get(process.env.VUE_APP_API_URL + "/teams4user/" + this.userid)
        .then((response) => {
          this.teams = response.data;
        });
    },
  },
  mounted() {
    let vm = this;
    let loadingbar = vm.$refs["progressbar"];
    this.getTeams();
    this.matchdate = this.getDate();
    $(document).ready(() => {
      // e.target.files[0].name;
      const chunkSize = 1 * 1024 * 1024; // The size of each chunk, set to 1 Megabyte
      // Use the Blob.slice method to split the file.
      // This method is also used differently in different browsers.
      const blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;

      const hashFile = (file) => {
        return new Promise((resolve, reject) => {
          const chunks = Math.ceil(file.size / chunkSize);
          let currentChunk = 0;
          const spark = new SparkMD5.ArrayBuffer();
          const fileReader = new FileReader();
          function loadNext() {
            const start = currentChunk * chunkSize;
            const end =
              start + chunkSize >= file.size ? file.size : start + chunkSize;
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
          }
          fileReader.onload = (e) => {
            spark.append(e.target.result); // Append array buffer
            currentChunk += 1;
            if (currentChunk < chunks) {
              loadNext();
            } else {
              console.log("finished loading");
              const result = spark.end();
              // If result s are used as hash values only, if the contents of the file are the same and the names are different
              // You cannot keep two files if you want to.So add the file name.
              const sparkMd5 = new SparkMD5();
              sparkMd5.append(result);
              sparkMd5.append(file.name);
              const hexHash = sparkMd5.end();
              resolve(hexHash);
            }
          };
          fileReader.onerror = () => {
            console.warn("File reading failed!");
          };
          loadNext();
        }).catch((err) => {
          console.log(err);
        });
      };

      const submitBtn = $("#submitBtn");
      submitBtn.on("click", async () => {
        const fileDom = $("#file")[0];
        // The resulting files are an array of File objects and, if multiple selections are allowed, multiple files
        const files = fileDom.files;
        const file = files[0];
        if (!file) {
          alert("No file was obtained");
          return;
        }
        if (file.name.indexOf(".zip") == -1) {
          alert("File must be a zip file");
          return;
        }
        const blockCount = Math.ceil(file.size / chunkSize); // Total number of slices
        const axiosPromiseArray = []; // axiosPromise array
        const hash = await hashFile(file); //File hash
        // After obtaining the file hash, if breakpoint continuation is required, it can be checked in the background based on the hash value.
        // See if the file has been uploaded and if the transfer is complete and the slices have been uploaded.         console.log(hash);

        let completedChunks = 0;
        this.$store.commit("changeuploaded", false);
        for (let i = 0; i < blockCount; i++) {
          const start = i * chunkSize;
          const end = Math.min(file.size, start + chunkSize);
          // Build a form
          const form = new FormData();
          form.append("file", blobSlice.call(file, start, end));
          form.append("name", file.name);
          form.append("total", blockCount);
          form.append("index", i);
          form.append("size", file.size);
          form.append("hash", hash);
          form.append("userid", vm.userid);

          // ajax submits a slice, where content-type is multipart/form-data
          const axiosOptions = {
            onUploadProgress: (e) => {
              // Progress in processing uploads
              console.log(blockCount, i, e, file);
              if (e.loaded == e.total) {
                completedChunks++;
                console.log(completedChunks + " - " + blockCount);
                console.log(vm.percentage(completedChunks, blockCount) + "%");
                loadingbar.style.width =
                  vm.percentage(completedChunks, blockCount) + "%";
              }
            },
          };
          // Add to Promise Array
          axiosPromiseArray.push(
            axios.post(
              process.env.VUE_APP_POSTS_SERVER + "/file/upload",
              form,
              axiosOptions
            )
          );
        }
        // Request merge of slice files after all slice uploads
        let matchDescrValue = document.getElementById("matchdescr").value;
        let matchDateValue = document.getElementById("matchdate").value;
        let homeTeamValue = document.getElementById("hometeam").value;
        let awayTeamValue = document.getElementById("awayteam").value;
        let analysisteamValue = document.getElementById("analysisteam").value;
        console.log(matchDescrValue + " - " + matchDateValue);

        // Aggiungo al nome del file l'id dell'utente per ovviare ad eventuali omonimie
        const userId = 0;

        await axios.all(axiosPromiseArray).then(() => {
          // Merge chunks
          const data = {
            size: file.size,
            name: userId + "_" + file.name,
            total: blockCount,
            hash,
            matchdescr: matchDescrValue,
            matchdate: matchDateValue,
            userid: vm.userid,
            hometeam: homeTeamValue,
            awayteam: awayTeamValue,
            analysisteam: analysisteamValue,
          };
          axios
            .post(process.env.VUE_APP_POSTS_SERVER + "/file/merge_chunks", data)
            .then((res) => {
              if (!res.data.startsWith("Error")) {
                console.log("Upload Successful");
                console.log(res.data, file);
                //alert("Upload Successful");
                this.$store.commit("changeuploaded", true);
              } else {
                console.log("Not valid team inside zip file");
                alert("Not valid team inside zip file");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      });
    });

    window.onload = () => {};
  },
};
</script>

<style lang="scss">
.uploadpost\: {
  width: 40%;
  &wrap {
    flex-direction: column;
    padding: 10px;
  }
  &container {
    display: flex;
    flex-direction: row;
    padding-bottom: 0px;
  }
  &upload {
    padding-right: 20px;
    width: 22%;
    label {
      width: 100%;
    }
    input[type="text"] {
      width: 100%;
    }

    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }

    .btn {
      border: 2px solid transparent;
      color: $yellow;
      background-color: transparent;
      padding: 8px 0px;
      border-radius: 8px;
      font-size: 20px;
      font-weight: bold;
    }

    .upload-btn-wrapper input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  &data {
    width: 26%;
    margin-left: 10px;
    margin-right: 10px;
    &-desc {
      margin-bottom: 10px;
      input[type="text"] {
        width: 100%;
        padding: 5px;
        background: transparent;
        border: var(--border);
        color: white;
        height: 34px;
        font-size: 18px;
      }
      select {
        width: 100%;
        padding: 2px;
        background: transparent;
        border: var(--border);
        color: white;
        height: 34px;
        font-size: 18px;
      }
    }
    &-date {
      input[type="date"] {
        width: 100%;
        height: 34px;
      }
      /* Removes the clear button from date inputs */
      input[type="date"]::-webkit-clear-button {
        display: none;
      }

      /* Removes the spin button */
      input[type="date"]::-webkit-inner-spin-button {
        display: none;
      }

      /* Always display the drop down caret */
      input[type="date"]::-webkit-calendar-picker-indicator {
        color: white;
      }

      /* A few custom styles for date inputs */
      input[type="date"] {
        appearance: none;
        -webkit-appearance: none;
        color: white;
        font-family: "Helvetica", arial, sans-serif;
        font-size: 18px;
        border: var(--border);
        background: transparent;
        padding: 5px;
        display: inline-block !important;
        visibility: visible !important;
      }

      input[type="date"],
      focus {
        color: white;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
      }
    }
  }
  &progress {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    height: 20px;
    &-full {
      position: relative;
    }
    &-bar {
      position: absolute;
      height: 100%;
      width: 0;
      background-color: $yellow;
    }
  }
  &button {
    display: flex;
    justify-content: flex-end;
    &-el {
      margin-top: 24px;
      margin-bottom: 10px;
      border: var(--border);
      background: $yellow;
      color: grey;
      font-size: 20px;
      text-transform: uppercase;
      padding: 0px 20px;
      height: 34px;
    }
  }
}
</style>
